// MAIN WINDOW FULL HEIGHT

function fullHeight() {
  var screenH = window.innerHeight;
  var sections = $('.full-height');
  var headerH = $('header').height();
  sections.each(function(index, section) {
    $(section).css('height', screenH - headerH);
    $(section).find('.container').css('height', '100%');
    $(section).find('.container').children().css('height', '100%');
    if($(section).hasClass('office') && $(window).width() < 1200) {
      $(section).css('height', 'initial');
      $(section).find('.container').children().css('height', 'initial');
    }
  })
}


// TEXTAREA AUTO HEIGHT

function auto_grow(element) {
  element.style.height = "5px";
  element.style.height = (element.scrollHeight)+"px";
}

$(document).ready(function() {

  // FOTORAMA SLIDER

  $('.fotorama').fotorama();

  // BRANDS TILE


  // CAREER POSITIONS

  function slidingPositions() {
    $('.career-position__header').on('click', function(e) {
      e.preventDefault();
      var $this = $(this),
        thisItem = $this.closest('.career-position'),
        thisContent = thisItem.find('.career-position__content'),
        handler = thisItem.find('career-position__header');
        duration = 500;

        if(!thisItem.hasClass('active')) {
          thisItem.addClass('active');
          handler.addClass('btn-active');
          thisContent.slideDown(duration);
        } else {
          thisContent.stop(true, true).slideUp(duration);
          thisItem.removeClass('active');
          handler.removeClass('btn-active');
        }
    });
  }

  slidingPositions();


  // SCROLL TO SECTION

  var btn = $('.front__call');
  var btn2 = $('.start__call'); // TODO
  var sectionToScroll = document.getElementById('target-scroll');
  btn.on('click touchstart', function() {
    $('html, body').animate({
      scrollTop: $(sectionToScroll).offset().top
    }, 'slow');
  })
  btn2.on('click touchstart', function() {
    $('html, body').animate({
      scrollTop: $(sectionToScroll).offset().top
    }, 'slow');
  })


  // POPUP MENU AND POPUP FOOTER MENU

  var footerBtn  = $('.footer__menu_handler');
  var menuFooter = $('.footer__popup-menu');
  footerBtn.on('click', function() {
    menuFooter.slideToggle({duration: 'slow', easing: 'swing'});
  });
  $('body').on('click', function(e) {
    if(e.target.className !== 'footer__popup-menu-item'
    && e.target.className !== 'footer__popup-menu active'
    && e.target.className !== 'footer__menu_handler') {
      menuFooter.removeClass('active');
    }
  });

  var menuItem = $('.header__menu_relative');
  var arrow = menuItem.find('.header__menu_arrow');
  var menu = menuItem.find('.header__popup-menu');
  arrow.on('mouseover', function(e) {
    targetMenu = $(e.target).parents('.header__menu_relative').find('.header__popup-menu');
    menu.removeClass('active');
    targetMenu.toggleClass('active');
  })


  $('body').on('click', function(e) {
    if(e.target.className !== 'popup-menu__item'
    && e.target.className !== 'header__popup-menu active'
    && e.target.className !== 'header__menu_arrow') {
      menu.removeClass('active')
    } else if (e.target.className !== 'footer__popup-menu-item'
    && e.target.className !== 'footer__popup-menu active'
    && e.target.className !== 'footer__handler_btn') {
      menuFooter.removeClass('active');
    }
  });

  // hide popup on scroll
	var scrollPrev = 0;
	$(window).scroll(function() {
    $('.header__popup-menu').removeClass('active');
		var scrolled = $(window).scrollTop();
		if ( scrolled > 0 ) {
			if ( scrolled < scrollPrev ) {
        $('.header__popup-menu').removeClass('active');
			} else {
        // $('.footer__popup-menu').removeClass('active');
			}
		}
	});

  // POPUP MOBILE MENU

  var burgerBtn  = $('.header__menubar');
  var menuMobile = $('.popup-mobile');
  function toggleMenu(){
    menuMobile.toggleClass('visible');
    $('body').toggleClass('body-fixed');
    $('.tiled-bg').toggle();
    $('.header-contacts').toggleClass('index-up');
  }
  burgerBtn.on('click', function () {return toggleMenu()} );


  // POPUP OTHER CASES
  $('.other-cases__info').each(function(index, otherCase) {
    $(otherCase).hover(function() {
      $(this).find('.other-cases__popup').toggleClass('active');
    })
  });

  // MAINSCREEN HEIGHT
  fullHeight();

  // SWIPER SLIDERS

  enableCasesSlider('#experience-slider');
  enableCasesSlider('#cases-slider');
  enableCaseSlider('#case-slider');

}); // $.ready

$(window).resize($.throttle(200, fullHeight));

  // CASES SLIDER

  enableCasesSlider = function(element) {
    var casesSwiper;
    var container = $(element);
    var wrapper = $(element).find('.swiper-wrapper');
    var slideControls = $(element).parent().find('.slider__controls');
    var breakpoint = window.matchMedia( '(min-width:768px)' );

    if (breakpoint.matches === false) {
      casesSwiper = new Swiper (element, {
        loop: true,
        a11y: true,
        keyboardControl: true,
        grabCursor: true,
        pagination: {
          el: '.cases-pagination',
          type: 'bullets',
        },
        navigation: {
          nextEl: '.cases-list__control--right',
          prevEl: '.cases-list__control--left',
        },
        breakpointsInverse: true,
        breakpoints: {
          320: {
            slidesPerView: 1,
          }
        },
      });
    } else {
      if ( casesSwiper !== undefined ) casesSwiper.destroy( true, true );
      slideControls.hide();
      container.css('overflow', 'initial');
      wrapper.css({'justify-content': 'space-between', 'flex-wrap' : 'wrap'});
    }
  }


 // TESTIMONIALS TEXT SLIDER

  var testimonialsSwiper = new Swiper ('#testimonials', {
    loop: true,
    a11y: true,
    keyboardControl: true,
    grabCursor: true,
    spaceBetween: 20,
    pagination: {
      el: '.testimonials-counter',
      type: 'fraction',
    },
    navigation: {
      nextEl: '.testimonials__control--right',
      prevEl: '.testimonials__control--left',
    },
    breakpointsInverse: true,
    slidesPerView: 1
  });


 // TESTIMONIALS IMAGES SLIDER


  var testimonialsImagesSwiper = new Swiper ('#testimonial-images', {
    loop: true,
    a11y: true,
    keyboardControl: true,
    grabCursor: true,
    pagination: {
      el: '.testImages__counter',
      type: 'fraction',
    },
    navigation: {
      nextEl: '.testImages__control--right',
      prevEl: '.testImages__control--left',
    },
    breakpointsInverse: true,
    spaceBetween: 20,
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      500: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      },
      1600: {
        slidesPerView: 5,
      }
    },
  });

// OTHER CASES SLIDER

  enableCaseSlider = function(element) {
    var caseSwiper;
    var container = $(element);
    var wrapper = $(element).find('.swiper-wrapper');
    var slides = $(element).find('.swiper-slide');
    var secondSlide = $(element).find('.swiper-slide')[1];
    var slideControls = $(element).parent().find('.slider__controls');
    var breakpoint1 = window.matchMedia( '(min-width:768px)' );
    var breakpoint2 = window.matchMedia( '(min-width:1200px)' );

    if (breakpoint1.matches === false && slides.length <= 1) {

      if ( caseSwiper !== undefined ) caseSwiper.destroy( true, true );
      slideControls.hide();
      container.css('overflow', 'initial');
      wrapper.css({'justify-content': 'space-around', 'flex-wrap' : 'wrap'});

    } else if (breakpoint1.matches === true && slides.length <= 2) {
      if ( caseSwiper !== undefined ) caseSwiper.destroy( true, true );

      slideControls.hide();
      // container.css('overflow', 'initial');
      slides.css('width', '50%');
      $(secondSlide).css('border-left', '1px solid rgba(255, 255, 255, .24)');
      wrapper.css({'justify-content': 'space-between', 'flex-wrap' : 'nowrap'});

    } else if (breakpoint2.matches === true && slides.length === 3) {
      if ( caseSwiper !== undefined ) caseSwiper.destroy( true, true );

      slideControls.hide();
      // container.css('overflow', 'initial');
      slides.css('width', '33%');
      $(secondSlide).css('border-left', '1px solid rgba(255, 255, 255, .24)');
      $(secondSlide).css('border-right', '1px solid rgba(255, 255, 255, .24)');
      wrapper.css({'justify-content': 'space-between', 'flex-wrap' : 'nowrap'});

    } else {
      casesSwiper = new Swiper (element, {
        loop: true,
        a11y: true,
        keyboardControl: true,
        grabCursor: true,
        pagination: {
          el: '.other-cases__counter',
          type: 'fraction',
        },
        navigation: {
          nextEl: '.other-cases__control--right',
          prevEl: '.other-cases__control--left',
        },
        breakpointsInverse: true,
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 3,
          }
        },
      });
    }
  }

  // CONTACT FORM

  $('.sendback__input').focus(function(e){
    $(e.target).css('border-bottom', '1.5px solid #8AC249');
  })

    $('.sendback__input').blur(function(e){
    $(e.target).css('border-bottom', '1.5px solid #4862B1');
  })

  function sendMail() {
    $('#back-form').on('submit', function(e) {
      e.preventDefault();
      $.ajax({
        url: '/send-mail',
        type: 'POST',
        data: JSON.stringify({
          message: $('textarea.sendback__input').val(),
          mail: $('input.sendback__input').val()
        }),
        contentType: "application/json; charset=utf-8",
        // error: function(xhr, desc, err) {
        //     console.log(xhr);
        //     console.log("Details0: " + desc + "\nError:" + err);
        // },
      }).always(function(msg) {
        if (msg === 'ok') {
          $('#back-form').trigger("reset");
        }
      });
    });
  }

  sendMail();
